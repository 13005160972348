import React, { useEffect, useState } from 'react';
import { AppBar, Typography, Box, Toolbar, Button, useMediaQuery, useTheme  } from '@mui/material';
import './BubbleAnimation.css'; 
import { keyframes } from '@mui/system';
import QRCode from 'qrcode.react';
const typeWriter = keyframes`
    from { width: 0 }
    to { width: 100% }
`;
const Footer = () => {
    return (
        <Box mt={10} display="flex" justifyContent="center">
           <Button
                href=""
                style={{ margin: '0 10px' }}
            >
              <img src={require("./tiktok.png")} alt="TikTok" style={{ width: '50px', margin: '0 10px' }} />

            </Button>
            <Button
                href="https://www.instagram.com/surpriseme_app/"
                style={{ margin: '0 10px' }}
            >
              <img src={require("./instagram.webp")} alt="Instagram" style={{ width: '50px', margin: '0 10px' }} />

            </Button>
        </Box>
    );
};
const pulsate = keyframes`
    0% {
        box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
    }
`;

const CenteredContent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
    const message = "Découvre tous les évènements des lieux de ta ville";
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="70vh">
            {/* Titre */}
            <Typography align="center" style={{ color: "white" }} variant={isMobile ? "h4" : "h2"} gutterBottom>
                Ne loupe <span style={{ fontWeight: 'bold', color: 'yellow' }}>plus jamais</span> d'évènements
            </Typography>

            {/* Sous-titre */}
            <Typography
                align="center"
                variant={isMobile ? "h7" : "h4"}
                style={{
                    color: "white",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    opacity: 1, // Affiche le texte si isVisible est vrai
                    transition: "opacity 20s ease-in-out", // Ajoute une transition pour un effet fluide
                }}
                gutterBottom
                className="typing-animation" // Ajoute une classe pour l'animation CSS
            >
                {message}
            </Typography>



            {/* QR Code */}
            <Box mt={4}>
                <QRCode value="https://docs.google.com/forms/d/e/1FAIpQLSfcjESeCnBMxRX-HoSmEn2SOOjt7ThWc2vYaYj0Vs4mjzhprg/viewform?usp=sf_link" />
            </Box>
            <div style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '300px',
                height: '300px',
                background: 'radial-gradient(circle at 90% 90%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.8) 100%)',
                zIndex: -1,
                opacity: 0.5, // Opacité réglable
            }}></div>
            {/* Logos des réseaux sociaux */}
           
        </Box>
    );
};

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  return (
    <AppBar position="static" style={{ backgroundColor: '#000' }}>
      <Toolbar >
        <img src={require('./sme.png')} alt="Logo" style={{ marginRight: 'auto', maxHeight: '90px' }} />
{/* 
    
        <Button style={{ marginRight: '20px' }}>
          <Typography  style={{ fontSize: isMobile ? '10px' : "12px", color: 'white' }}>
            A propos
          </Typography>
        </Button> */}

<Button
            href="https://docs.google.com/forms/d/e/1FAIpQLSfcjESeCnBMxRX-HoSmEn2SOOjt7ThWc2vYaYj0Vs4mjzhprg/viewform?usp=sf_link"
            style={{
                borderRadius: '20px',
                fontWeight: 'bold',
                height: "30px",
                backgroundColor: 'rgba(250, 255, 0, 1)',
                color: 'black',
                borderRadius: '20px',
                fontWeight: 'bold',
                height: "30px",
                backgroundColor: 'rgba(250, 255, 0, 1)',
                color: 'black',
                position: 'relative', // Ajout de position relative pour positionner le pseudo-élément
                animation: `${pulsate} 2s infinite`, // Utilisation de l'animation pulsate définie ci-dessus
                boxShadow: '0 0 20px 5px rgba(255, 255, 255, 0.5)', // Lueur constante
                
            }}
        >
            <Typography
                style={{
                    fontSize: isMobile ? '50%' : "10px",
                    padding: '10px',
                    fontWeight: 'bold',
                    color: 'black',
                    position: 'relative', // Ajout de position relative pour positionner le pseudo-élément
                    zIndex: 1, // Assure que le texte est au-dessus de la lueur
                }}
            >
                Téléchargez l'application
            </Typography>
        
        </Button>
        
        </Toolbar>
    </AppBar>
  );
};
const getRandomPosition = () => {
  const positions = ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%'];
  const randomIndex = Math.floor(Math.random() * positions.length);
  return positions[randomIndex];
};

const getRandomOpacity = () => {
  return Math.random() * 0.6 + 0.3; // Opacité aléatoire entre 0.3 et 0.9
};

const Images = ({ isMobile }) => {
  const [imagePositions, setImagePositions] = useState([]);
  const imagesSrc = ["difsport.png", "popup.png", "openmat.png", "restaurant.png", "soiree.png", "esport.png", "oneman.png", "livemusic.png"];

  useEffect(() => {
      const positions = [];
      const maxAttempts = 100;
      let attempts = 0;

      const checkCollision = (newPosition) => {
          for (const position of positions) {
              const diffX = Math.abs(position.x - newPosition.x);
              const diffY = Math.abs(position.y - newPosition.y);
              const distance = Math.sqrt(diffX ** 2 + diffY ** 2);

              if (distance < 20) {
                  return true;
              }
          }
          return false;
      };

      const generatePosition = () => {
          const newPosition = { x: getRandomPosition(), y: getRandomPosition() };
          return newPosition;
      };

      while (positions.length < 6 && attempts < maxAttempts) {
          const newPosition = generatePosition();
          if (!checkCollision(newPosition)) {
              positions.push(newPosition);
          }
          attempts++;
      }

      setImagePositions(positions);
  }, []);

  return (
      <>
          {imagePositions.map((position, index) => (
              <img
                  key={index}
                  src={require(`./${imagesSrc[index]}`)}
                  alt={`Image ${index}`}
                  className="blink-image"
                  style={{
                      position: 'absolute',
                      top: position.y,
                      left: position.x,
                      transform: 'translate(-50%, -50%)',
                      opacity: getRandomOpacity(),
                      width: isMobile ? '30%' : '15%',
                  }}
              />
          ))}
      </>
  );
};
const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

    return (
        <div style={{
            backgroundColor:"black",
            height: '100vh',
            overflow: 'hidden',
          }}>
            <Navbar></Navbar>
            <CenteredContent></CenteredContent>
            
          {/* <Images isMobile={isMobile}></Images> */}
            <Footer></Footer>
        </div>
      );
};

export default LandingPage;
