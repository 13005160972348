import React from 'react';
import LandingPage from './landingPage';

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage/>} />
    </Routes>
  </BrowserRouter>
  );
}
export default App;
